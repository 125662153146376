import styled from 'styled-components';
import { lighten } from 'polished';

import { devices } from '../../styles';

const Label = styled.label`
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 25px;
  display: block;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  height: 48px;
  border: 0;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.form.background};
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.form.shadow};
  outline: 0 none;
  padding: 10px;
  margin-bottom: 27px;
  font-size: 20px;
  line-height: 25px;
  -webkit-appearance: none;

  &:focus {
    box-shadow: 0 0 3pt 2pt ${({ theme }) => theme.form.shadow};
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  max-width: 480px;
  height: 200px;
  min-height: 200px;
  border: 0;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.form.background};
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.form.shadow};
  outline: 0 none;
  padding: 10px;
  margin-bottom: 27px;
  font-size: 20px;
  line-height: 25px;
  -webkit-appearance: none;

  &:focus {
    box-shadow: 0 0 3pt 2pt ${({ theme }) => theme.form.shadow};
  }

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const Button = styled.button`
  height: 48px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.form.button};
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.form.buttonText};
  padding: 8px 29px;
  transition: background-color 0.18s ease-in-out;
  cursor: pointer;
  display: block;
  border: 0;
  font-weight: 600;

  &:hover {
    background-color: ${({ theme }) => lighten(0.1, theme.form.button)};
  }

  > svg {
    margin-right: 5px;
  }
`;

const Form = styled.form`
  -webkit-appearance: none;
`;

Form.Label = Label;
Form.Input = Input;
Form.TextArea = TextArea;
Form.Button = Button;

export default Form;
