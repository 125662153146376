import styled from 'styled-components';

import { devices } from '../../styles';

const ContactPage = styled.div`
  width: 480px;
  margin: 0 auto;

  @media ${devices.mobile} {
    width: 100%;
  }
`;

export default ContactPage;
